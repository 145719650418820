.expenses {
  padding: 1rem;
  background-color: rgb(31, 31, 31);
  margin: 2rem auto;
  width: 50rem;
  max-width: 95%;
}

body {
  background-color: rgb(44, 43, 43);
  margin: 0;
  padding: 0;
}
